import React from 'react';
import './Home.css';
import './Journal.css';

function Journal() {
    return (
        <div className="JournalTitleWrapper">
            <h1>Journal</h1>
            <div className="JournalContent">
                <div className="Journal-message">
                    Coming Soon!
                </div>
            </div>
        </div>
    );
}

export default Journal;
